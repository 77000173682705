<template>
  <nav
    class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow d-flex flex-row"
    :style="displayValidate()"
    style="
      transition: 0.4s;
      box-shadow: 0 0px 2px 0 rgba(58, 59, 69, 0.15) !important;
    "
  >
    <!-- Sidebar Toggle (Topbar) -->
    <!-- <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
      <i class="fa-duotone fa-bars"></i>    </button> -->

    <div
      class="panelLabel"
      style="
        position: absolute;
        left: 26px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
      "
      v-if="!$isMobile"
    >
      <i class="fa-solid fa-screwdriver-wrench mr-3" style="color: #2196f3"></i>
      <template v-if="$store.state.user?.type == 'admin'">
        Panel de administrador
      </template>
      <template v-if="$store.state.user?.type == 'concesionario'">
        Panel de cliente
      </template>
      <template v-if="$store.state.user?.type == 'taller'">
        Panel de taller
      </template>
      <template v-if="$store.state.user?.type == 'coordinador'">
        Panel de coordinador
      </template>
    </div>
    
    <i class="fa-solid fa-bars" v-if="$isMobile" @click="$store.state.menuMobileScreen = true" style="position: absolute;
    left: 18px;
    font-size: 20px;"></i>

    <!-- Topbar Search -->
    <!-- <form
            class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search"
          >
            <div class="input-group">
              <input
                type="text"
                class="form-control bg-light border-0 small"
                placeholder="Search for..."
                aria-label="Search"
                aria-describedby="basic-addon2"
              />
              <div class="input-group-append">
                <button class="btn btn-primary" type="button">
                  <i class="fas fa-search fa-sm"></i>
                </button>
              </div>
            </div>
          </form> -->

    <!-- Topbar Navbar -->
    <ul class="navbar-nav ml-auto" style="border-right: none">

      <!-- Nav Item - Alerts -->
      <li class="nav-item dropdown no-arrow mx-1">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="alertsDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i
            class="fas fa-bell fa-fw"
            @click.prevent="actualizarNotificaciones()"
            style="color: #333333c4"
          ></i>
          <!-- Counter - Alerts -->
          <i
            v-if="$store.state.notificacionesActive == true"
            class="fa-solid fa-circle"
            style="
              color: red;
              font-size: 7px;
              margin-top: -14px;
              margin-left: -7px;
            "
          ></i>
        </a>
        <!-- Dropdown - Alerts -->
        <div
          v-if="!$isMobile"
          class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
          aria-labelledby="alertsDropdown"
        >
          <h6 class="dropdown-header">Notificaciones</h6>
          <div
            v-if="$store.state.notificaciones[0]"
            style="overflow: scroll; height: 400px"
          >
            <a
              v-for="(item, index) in $store.state.notificaciones"
              :key="index"
              @click.prevent="openModalService(item.servicios)"
              class="dropdown-item d-flex align-items-center"
              href="#"
            >
              <div class="mr-3">
                <div class="icon-circle" style="background: #eee">
                  <i class="fas fa-bell"></i>
                </div>
              </div>
              <div>
                <div>
                  <div class="small text-gray-500">{{ item.title }}</div>
                  <span class="font-weight-bold">{{ item.body }}</span>
                </div>
                <div>
                  <div class="fecha" style="font-size: 10px; float: right">
                    {{ formatTime(item.created_at) }}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div v-else>
            <div style="padding: 14px">
              <strong>No tiene notificaciones</strong>
            </div>
          </div>
        </div>
      </li>

      <div class="topbar-divider d-none d-sm-block"></div>

      <!-- Nav Item - User Information -->
      <li class="nav-item dropdown no-arrow">
        <a 
          class="nav-link dropdown-toggle"
          href="#"
          id="userDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="d-flex flex-column" :style="$isMobile?{'margin-right':'15px'}:{}">
            <span
              class="mr-2 d-none d-lg-inline text-gray-600 small"
              style="font-weight: 700"
              >{{ $store.state.user?.name }}</span
            >
            <span
              style="
                font-size: 11px;
                font-weight: 600;
                color: #000;
                text-transform: capitalize;
              "
              >{{ $store.state.user?.type }}</span
            >
          </div>

          <div class="letraIcon d-flex">
            {{ $store.state.user.name.substr(0, 1) }}
          </div>
        </a>
        <!-- Dropdown - User Information -->
        <div
          class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
          aria-labelledby="userDropdown"
        >
          <small
            style="
              text-transform: uppercase;
              padding-left: 25px;
              font-size: 9px;
              font-weight: bold;
              color: #8e8e8e;
            "
            >{{ $store.state.user.type }}</small
          >

          <div class="dropdown-divider"></div>
          <a v-if="!$isMobile" class="dropdown-item" href="#" @click.prevent="irRuta('Profile')">
            <i
              class="fa-regular fa-fw fa-cog fa-sm fa-fw mr-2 text-gray-400"
            ></i>
            Mi cuenta
          </a>

          <div class="dropdown-divider"></div>
          <a
            @click.prevent="logout()"
            class="dropdown-item"
            href="#"
            data-toggle="modal"
            data-target="#logoutModal"
          >
            <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
            Cerrar sesión
          </a>
        </div>
      </li>
    </ul>

  <notifications-mobile v-if="$isMobile && $store.state.notificacionesActiveFull" v-animate-css="'slideInUp'" />
 
  </nav>
</template>

<script>
import NotificationsMobile from './notificationsMobile.vue';

export default {
  components: {NotificationsMobile},
  mounted() {
    this.listarNotificaciones();
  },
  data() {
    return {
      notificaciones: [],
      mostrar: null,
    };
  },
  watch: {
    "$store.state.notificacionesActive"(val) {
      if (val) {
        console.log("entro en la actualización");
        this.listarNotificaciones();
      }
    },
  },
  methods: {
    displayValidate() {
      if (!this.$isMobile) {
        if (this.$store.state.screen) {
          return { right: "0", left: "224px", "z-index": "1" };
        } else {
          return { right: "0", left: "105px", "z-index": "1" };
        }
      }else {
        return { right: "0", left: "0", "z-index": "1" };
      }
    },
    irRuta(ruta) {
      if (ruta != this.$route.name) {
        this.$router.push({ name: ruta });
      }
    },
    actualizarNotificaciones() {
      if(this.$isMobile){
        this.$store.state.notificacionesActiveFull = true;
      }
      localStorage.setItem("notificacionesActive", JSON.stringify(false));
      this.$store.state.notificacionesActive = false;
    },
    openModalService(item) {
      if(this.$isMobile){
        this.$store.state.notificacionesActiveFull = false;
      }
      this.$store.state.mostrar = item;
      $("#verServicioModal").modal("show");
    },
    listarNotificaciones() {
      let url = this.$base.dominio + "/lista/notificaciones";
      this.$fn.Notifications(this.$store.state.user.id);
      // axios.post(url, datos).then((response) => {
      //   console.log(response.data)
      //   this.notificaciones = response.data;
      // });
    },

    formatTime(date) {
      return this.moment(date).fromNow();
    },

    logout() {
      this.$store
        .dispatch("destroyToken")
        .then((response) => {
          window.location.href = "/";
        })
        .catch((error) => {
          toastr.error("Error al cerrar la sesión");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.topbar .dropdown-list {
  width: 27rem !important;
}

.letraIcon {
  background: #f4f4f5;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
  border-radius: 50px;
  /* box-shadow: 0px 0px 10px 7px #eee; */
  color: #333;
  border: 1px solid #dbdbdb;
}
.navbar {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem 1rem;
  right: 0;
  left: 224px;
  z-index: 100;
  justify-content: end;
}
</style>
