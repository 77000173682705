var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"animate-css",rawName:"v-animate-css",value:('slideIn'),expression:"'slideIn'"}],staticClass:"modal fade",staticStyle:{"overflow-y":"scroll"},attrs:{"id":"verServicioModal","tabindex":"-1"}},[_c('div',{staticClass:"modal-dialog modal-lg modal-dialog-centered"},[_c('div',{staticClass:"modal-content",staticStyle:{"min-height":"790px"}},[(_vm.loaderBloque)?_c('LoaderBloque',{attrs:{"mensaje":"Cargando por favor espere..."}}):_vm._e(),_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"justify-content":"space-between","width":"100%"}},[(!_vm.$isMobile)?_c('h5',{staticClass:"modal-title"},[_c('img',{staticStyle:{"width":"40px","margin-right":"20px"},attrs:{"src":"/img/marca/destino.png"}}),_vm._v(" Solicitud de servicio #"+_vm._s(_vm.$store.state.mostrar?.id)+" ")]):_vm._e(),(_vm.$isMobile)?_c('h5',{staticClass:"modal-title"},[_c('img',{staticStyle:{"width":"40px","margin-right":"20px"},attrs:{"src":"/img/marca/destino.png"}}),_vm._v(" #"+_vm._s(_vm.$store.state.mostrar?.id)+" ")]):_vm._e(),(_vm.$store.state.mostrar)?_c('strong',{staticClass:"d-flex flex-row",staticStyle:{"align-items":"center","margin-right":"25px","padding-inline":"10px","justify-content":"center","box-shadow":"0px 0px 13px 5px #eee"}},[_c('i',{staticClass:"mr-1 fa-solid fa-circle",class:{
                'blinking-icon':
                  _vm.$store.state.mostrar.estado == 'En curso' ||
                  _vm.$store.state.mostrar.estado == 'En recogida' ||
                  _vm.$store.state.mostrar.estado == 'En entrega',
              },staticStyle:{"font-size":"8px"},style:({
                color: _vm.$parent.functionColorState(_vm.$store.state.mostrar),
              })}),_c('div',[_vm._v(" "+_vm._s(_vm.$store.state.mostrar.estado == "curso" ? "En curso" : _vm.$store.state.mostrar.estado)+" ")])]):_vm._e()]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){_vm.$store.state.mostrar = null}}},[_c('i',{staticClass:"fa-light fa-xmark"})])]),(_vm.$isMobile)?_c('b-dropdown',{staticStyle:{"width":"fit-content","float":"right","justify-content":"end","align-self":"flex-end"},attrs:{"id":"dropdown-right","right":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fa-sharp fa-solid fa-ellipsis"})]},proxy:true}],null,false,3449920975)},[(
            _vm.$store.state.user.type != 'taller' &&
            _vm.$store.state.user.type != 'concesionario' &&
            !_vm.$store.state.mostrar?.conductor_id &&
            _vm.$store.state.mostrar?.estado != 'Fallido' &&
            _vm.$store.state.mostrar?.estado != 'Terminado'
          )?_c('b-dropdown-item-button',{on:{"click":function($event){$event.preventDefault();return _vm.openAsignar(_vm.$store.state.mostrar)}}},[_vm._v("Asignar")]):_vm._e(),(
            _vm.$store.state.user.type != 'taller' &&
            _vm.$store.state.user.type != 'concesionario' &&
            _vm.$store.state.mostrar?.conductor_id &&
            _vm.$store.state.mostrar?.estado != 'Terminado' &&
            _vm.$store.state.mostrar?.estado != 'Fallido'
          )?_c('b-dropdown-item-button',{on:{"click":function($event){$event.preventDefault();return _vm.openAsignar(_vm.$store.state.mostrar)}}},[_vm._v("Reasignar conductor")]):_vm._e(),(
            _vm.$store.state.user.type != 'taller' &&
            _vm.$store.state.user.type != 'concesionario' &&
            _vm.$store.state.mostrar?.estado != 'Fallido' &&
            _vm.$store.state.mostrar?.estado != 'Terminado'
          )?_c('b-dropdown-item-button',{on:{"click":function($event){$event.preventDefault();return _vm.marcarFailed(_vm.$store.state.mostrar)}}},[_vm._v("Marcar como fallida")]):_vm._e(),_c('a',{attrs:{"href":_vm.$base.dominio +
            '/generate/report/' +
            _vm.$store.state.mostrar?.id +
            '/service',"target":"_blank"}},[(_vm.$store.state.mostrar?.estado == 'Terminado')?_c('b-dropdown-item-button',[_vm._v("Descargar pdf reporte")]):_vm._e()],1)],1):_vm._e(),_c('menu-service-data'),(_vm.$store.state.mostrar)?_c('div',{staticClass:"modal-body",staticStyle:{"padding":"0"}},[(_vm.$store.state.mostrar)?_c('div',{staticClass:"d-flex flex-column"},[(_vm.menu == 1)?_c('informacion-google-map',{directives:[{name:"animate-css",rawName:"v-animate-css",value:('fadeIn'),expression:"'fadeIn'"}],attrs:{"servicio":_vm.$store.state.mostrar}}):_vm._e(),_c('div',{staticStyle:{"padding":"10px"}},[(_vm.menu == 1)?_c('informacion-basica',{directives:[{name:"animate-css",rawName:"v-animate-css",value:('fadeIn'),expression:"'fadeIn'"}],attrs:{"servicio":_vm.$store.state.mostrar}}):_vm._e(),(_vm.menu == 4)?_c('informacion-data',{directives:[{name:"animate-css",rawName:"v-animate-css",value:('fadeIn'),expression:"'fadeIn'"}],attrs:{"servicio":_vm.$store.state.mostrar}}):_vm._e(),(_vm.menu == 2)?_c('InformacionCliente',{directives:[{name:"animate-css",rawName:"v-animate-css",value:('fadeIn'),expression:"'fadeIn'"}],attrs:{"servicio":_vm.$store.state.mostrar}}):_vm._e(),(_vm.menu == 3)?_c('informacion-vehiculo',{directives:[{name:"animate-css",rawName:"v-animate-css",value:('fadeIn'),expression:"'fadeIn'"}],attrs:{"servicio":_vm.$store.state.mostrar}}):_vm._e(),(_vm.menu == 5)?_c('informacion-facturacion',{directives:[{name:"animate-css",rawName:"v-animate-css",value:('fadeIn'),expression:"'fadeIn'"}],attrs:{"servicio":_vm.$store.state.mostrar}}):_vm._e()],1)],1):_vm._e()]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }