var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{directives:[{name:"animate-css",rawName:"v-animate-css",value:('slideInLeft'),expression:"'slideInLeft'"}],staticClass:"navbar-nav sidebar sidebar-dark accordion",class:{ toggled: _vm.active },staticStyle:{"transition":"0.4s"},attrs:{"id":"accordionSidebar"}},[_c('a',{staticClass:"sidebar-brand",style:(_vm.$store.state.screen ? { 'padding-inline': 0 } : { padding: '1.5rem 1rem' }),attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.irHome()}}},[(_vm.$store.state.screen)?_c('img',{directives:[{name:"animate-css",rawName:"v-animate-css",value:('slideInLeft'),expression:"'slideInLeft'"}],staticStyle:{"transition":"0.4s","width":"146px","float":"left","margin-left":"15px"},attrs:{"src":"/img/marca/logo.png","alt":""}}):_vm._e(),(!_vm.$store.state.screen)?_c('img',{directives:[{name:"animate-css",rawName:"v-animate-css",value:('slideInLeft'),expression:"'slideInLeft'"}],staticStyle:{"transition":"0.4s","width":"76px","float":"left"},attrs:{"src":"/img/marca/logo2.png","alt":""}}):_vm._e()]),_c('hr',{staticClass:"sidebar-divider my-0"}),_c('div',{staticClass:"sidebar-heading",staticStyle:{"color":"rgb(121 121 121)","margin-top":"40px"}},[_vm._v(" Opciones de sistema ")]),(_vm.$store.state?.user?.type == 'admin')?_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.ruta('Home')}}},[_c('a',{staticClass:"nav-link",class:{
          active: _vm.$route.name == 'Home',
          activeFondo: _vm.$route.name == 'Home',
        },attrs:{"href":"index.html"}},[_c('i',{staticClass:"fas fa-fw fa-tachometer-alt"}),_c('span',[_vm._v("Dashboard")])])]):_vm._e(),_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.ruta('Services')}}},[_c('a',{staticClass:"nav-link",class:{
          active: _vm.$route.name == 'Services',
          activeFondo:
            _vm.$route.name == 'Services' ||
            _vm.$route.name == 'createServices' ||
            _vm.$route.name == 'updateServices',
        },attrs:{"href":"#"}},[_c('i',{staticClass:"fa-regular fa-fw fa-car",class:{
            active:
              _vm.$route.name == 'Services' ||
              _vm.$route.name == 'createServices' ||
              _vm.$route.name == 'updateServices',
          }}),_c('span',[_vm._v("Servicios")])])]),(_vm.$store.state.user.type == 'admin' || _vm.$store.state.user.type == 'coordinador')?_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.ruta('serviceRequest')}}},[_c('a',{staticClass:"nav-link",class:{
          active: _vm.$route.name == 'serviceRequest',
          activeFondo:
            _vm.$route.name == 'serviceRequest'
        },attrs:{"href":"#"}},[_c('i',{staticClass:"fa-solid fa-bell-on",class:{
              active:
              _vm.$route.name == 'serviceRequest',  danger: _vm.requests_count > 0,
              heartbeat: _vm.requests_count > 0 // Aplicar la animación solo si 'danger' está activo
          }}),_c('span',[_vm._v("Solicitudes")])])]):_vm._e(),(_vm.$store.state.user.type == 'admin')?_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.ruta('Dealers')}}},[_c('a',{staticClass:"nav-link",class:{
          active: _vm.$route.name == 'Dealers' || _vm.$route.name == 'dealersManagement',
          activeFondo: _vm.$route.name == 'Dealers' || _vm.$route.name == 'dealersManagement',
        },attrs:{"href":"charts.html"}},[_c('i',{staticClass:"fa-regular fa-fw fa-car-garage",class:{
            active: _vm.$route.name == 'Dealers' || _vm.$route.name == 'dealersManagement',
          }}),_c('span',[_vm._v("Concesionarios")])])]):_vm._e(),(_vm.$store.state.user?.type == 'concesionario')?_c('div',{staticClass:"d-flex flex-column",staticStyle:{"justify-content":"center"}},[_c('br'),_c('div',{staticStyle:{"height":"1px","background":"#eee","width":"90%","margin":"auto"}}),_c('br')]):_vm._e(),(_vm.$store.state.user.type != 'taller' && _vm.$store.state.user.type != 'coordinador' && _vm.$store.state.user.type != 'admin')?_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.ruta('Taller')}}},[_c('a',{staticClass:"nav-link",class:{
          active: _vm.$route.name == 'Taller',
          activeFondo: _vm.$route.name == 'Taller',
        },attrs:{"href":"#"}},[_c('i',{staticClass:"fa-regular fa-fw fa-car-wrench"}),(_vm.$store.state.user?.type == 'concesionario')?_c('span',[_vm._v("Mis talleres")]):_vm._e()])]):_vm._e(),(_vm.$store.state.user.type == 'admin')?_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.ruta('Conductores')}}},[_c('a',{staticClass:"nav-link",class:{
          active: _vm.$route.name == 'Conductores',
          activeFondo: _vm.$route.name == 'Conductores',
        },attrs:{"href":"#"}},[_c('i',{staticClass:"fa-sharp fa-regular fa-id-card"}),_c('span',[_vm._v("Conductores")])])]):_vm._e(),(_vm.$store.state.user.type == 'admin')?_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.ruta('Coordinador')}}},[_c('a',{staticClass:"nav-link",class:{
          active: _vm.$route.name == 'Coordinador',
          activeFondo: _vm.$route.name == 'Coordinador',
        },attrs:{"href":"#"}},[_c('i',{staticClass:"fa-regular fa-fw fa-users-cog"}),_c('span',[_vm._v("Coordinadores")])])]):_vm._e(),(_vm.$store.state.user.type == 'concesionario')?_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.ruta('adminConcesionarioInvoices')}}},[_c('a',{staticClass:"nav-link",class:{
          active: _vm.$route.name == 'adminConcesionarioInvoices',
          activeFondo: _vm.$route.name == 'adminConcesionarioInvoices',
        },attrs:{"href":"#"}},[_c('i',{staticClass:"fa-regular fa-fw fa-file-invoice"}),_c('span',[_vm._v("Facturación")])])]):_vm._e(),(_vm.$store.state.user.type == 'admin')?_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.ruta('Invoices')}}},[_c('a',{staticClass:"nav-link",class:{
          active: _vm.$route.name == 'Invoices',
          activeFondo: _vm.$route.name == 'Invoices',
        },attrs:{"href":"#"}},[_c('i',{staticClass:"fa-regular fa-fw fa-file-invoice"}),_c('span',[_vm._v("Facturación")])])]):_vm._e(),(_vm.$store.state.user.type == 'admin')?_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.ruta('Version_Apk')}}},[_c('a',{staticClass:"nav-link",class:{
          active: _vm.$route.name == 'Version_Apk',
          activeFondo: _vm.$route.name == 'Version_Apk',
        },attrs:{"href":"#"}},[_c('i',{staticClass:"fa-brands fa-android"}),_c('span',[_vm._v("APK")])])]):_vm._e(),_c('hr',{staticClass:"sidebar-divider d-none d-md-block"}),_c('div',{staticClass:"text-center d-none d-md-inline"},[_c('button',{staticClass:"rounded-circle border-0",attrs:{"id":"sidebarToggle"},on:{"click":function($event){$event.preventDefault();return _vm.activateSidebar()}}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }