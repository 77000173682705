import axios from "axios";
import { dominio } from "./base";
import state from "../store/state";
import toastr from "toastr";

export function Notifications(user_id) {
  let url = dominio + "/lista/notificaciones";
  const datos = {
    user_id
  }
  axios
    .post(url, datos)
    .then((res) => {
      state.notificaciones = res.data;
    })
    .catch((e) => {
      console.log(`error al cargar las notificaciones`);
    });
}

export function formatoPrecio(precio) {
  if (typeof precio !== "number") {
    return precio;
  }
  // Convertimos a string y luego usamos replace para agregar los miles y mantener los decimales
  return precio.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}



export function validarDNI(dni) {
  if (dni.length < 7 || dni.length > 11) {
    return true;
  } else {
    false;
  }
}

export function alertError(e) {
  if (e.response && e.response.data.errors) {
    const errors = e.response.data.errors;
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        errors[key].forEach((err) => {
          toastr.error(err);
        });
      }
    }
  } else {
    toastr.error("Ha ocurrido un error inesperado");
  }
}

export function activarAudio() {
  $("#audio").prop("muted", true);
  $("#audio")[0].play();
}
