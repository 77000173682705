"use strict";

import Vue from 'vue';
import axios from "axios";

// Configuración básica de axios
let config = {
  // Puedes agregar la base URL si es necesario
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
};

const _axios = axios.create(config);

// Interceptor de solicitud para agregar el token
_axios.interceptors.request.use(
  function(config) {
    // Obtener el token del localStorage
    const token = localStorage.getItem('access_token');

    // Si el token existe, agregarlo a los headers de la solicitud
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Continuar con la solicitud
    return config;
  },
  function(error) {
    // Manejar el error en la solicitud
    return Promise.reject(error);
  }
);

// Interceptor de respuesta
_axios.interceptors.response.use(
  function(response) {
    // Manejar la respuesta exitosa
    return response;
  },
  function(error) {
    // Manejar errores, como token inválido o expirado
    if (error.response && error.response.status === 401) {
      // Eliminar el token si es necesario
      localStorage.removeItem('access_token');
      localStorage.removeItem('user');
      // Redirigir al usuario al login si el token es inválido o ha expirado
      window.location.href = '/';
    }

    // Propagar el error si es otro tipo de error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin);

export default Plugin;
