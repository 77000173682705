window.$ = window.jQuery = require('jquery');
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store/store'
import deviceDetector from './plugins/device-detector';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/js/bootstrap.js'


Vue.use(BootstrapVue)
Vue.use(IconsPlugin)


// alert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);


import VueToastr2 from 'vue-toastr-2'
import 'vue-toastr-2/dist/vue-toastr-2.min.css'
window.toastr = require('toastr')
Vue.use(VueToastr2)


import moment from 'moment'
import 'moment/locale/es';
moment.locale('es');

Vue.prototype.moment = moment;


import VAnimateCss from 'v-animate-css';
Vue.use(VAnimateCss);

// Variables globales 
import * as tb from "./config/base";
Vue.prototype.$base = tb;

import * as fun from "./config/funtionsglobal";
Vue.prototype.$fn = fun;

import * as VueGoogleMaps from 'vue2-google-maps'
 
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCWJVaVgi7p7by3vsMC_8EUM3z3z4xmvak',
    libraries: 'places, routes'
  }
}); 


import VTooltip from 'v-tooltip'
Vue.use(VTooltip)


import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
Vue.use(VueTelInput);

import PrimeVue from 'primevue/config';
Vue.use(PrimeVue, {ripple: true});
// import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import 'primevue/resources/themes/tailwind-light/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';


// Plugin para verificar el tamaño 
Vue.use(deviceDetector);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')