<template>
  <div
    class="informacionCliente"
    style="padding-top: 48px; padding-bottom: 30px"
    v-if="servicio"
  >
    <div
      class="d-flex flex-row"
      style="align-items: center; align-items: center;"
      :style="!$isMobile?{'padding-inline': '59px'}:{'padding-inline': '10px'}"
    >
      <div class="d-flex flex-column">
        <strong style="font-size: 17px">Información del taller</strong>
      </div>
    </div>

    <div class="bloqueInfBasica mt-4">
      <div class="divColumnaLarge d-flex flex-column mt-2 pb-1">
        <div>
          <strong>{{ servicio.taller?.nombre }}</strong>
        </div>

        <div class="d-flex flex-column" style="margin-top: 20px">
          <small>Teléfono</small>
          <div>{{ servicio.taller.telefono }}</div>
        </div>
      </div>
    </div>

    <hr />
    <div
      class="d-flex flex-row"
      style="align-items: center; align-items: center; "
      :style="!$isMobile?{'padding-inline': '59px'}:{'padding-inline': '10px'}"
    >
      <div class="d-flex flex-column">
        <strong style="font-size: 17px">Información del vehículo</strong>
      </div>
    </div>

    <div class="bloqueInfBasica mt-4">
      <div class="divColumna d-flex mt-2">
        <div class="clave">Matricula</div>
        <div class="Valor" style="text-transform: uppercase">
          {{ servicio.matricula }}
        </div>
      </div>

      <div class="divColumna d-flex mt-2">
        <div class="clave">Modelo</div>
        <div class="Valor" style="text-transform: uppercase">{{ servicio.modelo }}</div>
      </div>

      <div class="divColumna d-flex mt-2">
        <div class="clave">Color</div>
        <div class="Valor">
          {{
            servicio.color && servicio.color != "null"
              ? servicio.color
              : "Sin especificar"
          }}
        </div>
      </div>

      <div class="divColumna d-flex mt-2">
        <div class="clave">Combustible</div>
        <div class="Valor">
          {{
            servicio.combustible && servicio.combustible != "null"
              ? servicio.combustible
              : "Sin especificar"
          }}
        </div>
      </div>

      <div class="divColumna d-flex mt-2">
        <div class="clave">Kilómetros al iniciar el servicio</div>
        <div class="Valor">
          {{
            servicio.kilometros && servicio.kilometros != "null"
              ? servicio.kilometros + " KM"
              : "No agregado"
          }}
        </div>
      </div>

      <div class="divColumna d-flex mt-2">
        <div class="clave">Kilómetros al finalizar el servicio</div>
        <div class="Valor">
          {{
            servicio.kilometros_final && servicio.kilometros_final != "null"
              ? servicio.kilometros_final + " KM"
              : "No agregado"
          }}
        </div>
      </div>

      <!-- <div class="divColumna d-flex mt-2">
        <div class="clave">Número OR</div>
        <div class="Valor">{{ servicio.numero_or }}</div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: ["servicio"],
};
</script>
<style lang="scss" scope>
 @media(max-width:768px){
  .bloqueInfBasica {
  padding-inline: 10px !important;
}
 }
 .bloqueInfBasica {
  padding-inline: 60px;
}
.divColumna {
  justify-content: space-between;
  .clave {
    width: 40%;
  }
  .Valor {
    text-align: right;
    font-weight: 600;
  }
}
</style>
