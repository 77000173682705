<template>
    <div class="indexCrate">
            <Nav />

            <div class="container-fluid" style="padding-inline: 100px; margin-top:20px">
                 <router-view />
            </div>
    </div>
</template>
<script>
import Nav from './nav.vue'
export default {
  components: { Nav },
    
}
</script>