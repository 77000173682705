<template>
  <div class="notificationsMobile">
    <strong style="margin-left: 20px; font-size: 23px">Notificaciones</strong>
    <div class="iconClose d-flex" style="" @click.prevent="$store.state.notificacionesActiveFull = false">
        <i class="fa-solid fa-close"></i>
    </div>
    <div
      v-if="$store.state.notificaciones[0]"
      style="overflow: scroll; height: 100%; padding-inline:20px"
    >
      <a
        v-for="(item, index) in $store.state.notificaciones"
        :key="index"
        @click.prevent="$parent.openModalService(item.servicios)"
        class="d-flex align-items-center"
        href="#"
        style="margin-bottom:10px; text-decoration: none;"
      >
        <div class="mr-3">
          <div class="icon-circle" style="background: #eee">
            <i class="fas fa-bell"></i>
          </div>
        </div>
        <div>
          <div>
            <div class="small text-gray-500">{{ item.title }}</div>
            <span class="font-weight-bold" style="color:#333">{{ item.body }}</span>
          </div>
          <div>
            <div class="fecha" style="font-size: 10px; float: right">
              {{ $parent.formatTime(item.created_at) }}
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
    methods: {
    
    }
};
</script>

<style lang="scss" scoped>
.notificationsMobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 100;
  padding-block: 50px;

  .iconClose {
    padding: 10px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 35px;
    border: 1px solid #333;
    height: 40px;
    width: 40px;
    border-radius: 50px;
  }

  .list-group-item {
    // border-bottom: 1px solid #c9c9c9 !important;
    border-bottom: none !important;
  }
}
</style>
