<template>
  <div class="informacionCliente" v-if="servicio.cliente" style="padding-top: 48px">
    <div
      class="d-flex flex-row"
      style="align-items: center; align-items: center;"
      :style="!$isMobile?{'padding-inline': '59px'}:{'padding-inline': '10px'}"
    >
      <div class="d-flex flex-column">
        <strong style="font-size: 17px">Información del conductor</strong>
      </div>
    </div>

    <div class="bloqueInfBasica mt-2" v-if="servicio.conductor">
      <div class="divColumna d-flex mt-2">
        <div class="clave">Nombre del conductor</div>
        <div class="Valor">{{ servicio.conductor?.name }}</div>
      </div>

      <div class="divColumna d-flex mt-2">
        <div class="clave">Teléfono</div>
        <div class="Valor">{{ servicio.conductor?.telefono }}</div>
      </div>

      <div class="divColumna d-flex mt-2">
        <div class="clave">Correo electrónico</div>
        <div class="Valor">{{ servicio.conductor?.email }}</div>
      </div>

      <div class="divColumna d-flex mt-2">
        <div class="clave">DNI</div>
        <div class="Valor">{{ servicio.conductor?.nif }}</div>
      </div>
    </div>
    <div v-else style=" color: #ff9800; margin-top: 20px" :style="!$isMobile?{'padding-inline': '59px'}:{'padding-inline': '10px'}">
      <strong>El servicio no tiene conductor asignado.</strong>
    </div>

    <hr />
    <div
      class="d-flex flex-row"
      style="align-items: center; align-items: center; "
      :style="!$isMobile?{'padding-inline': '59px'}:{'padding-inline': '10px'}"
    >
      <div class="d-flex flex-column">
        <strong style="font-size: 17px">Información del cliente</strong>
      </div>
    </div>

    <div class="bloqueInfBasica mt-2">
      <div class="divColumna d-flex mt-2">
        <div class="clave">Nombre del cliente</div>
        <div class="Valor">{{ servicio.cliente.nombre }}</div>
      </div>

      <div class="divColumna d-flex mt-2">
        <div class="clave">Teléfono</div>
        <div class="Valor">{{ servicio.cliente.telefono }}</div>
      </div>

      <div class="divColumna d-flex mt-2">
        <div class="clave">Correo electrónico</div>
        <div class="Valor">{{ servicio.cliente?.email && servicio.cliente?.email != 'null'?servicio.cliente.email:'No agregado' }}</div>
      </div>

      <div class="divColumna d-flex mt-2">
        <div class="clave">DNI</div>
        <div class="Valor">{{ servicio.cliente?.dni && servicio.cliente?.dni != 'null'? servicio.cliente?.dni : 'No agregado'}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["servicio"],
};
</script>
<style lang="scss" scope>
@media(max-width:768px){
  .bloqueInfBasica {
  padding-inline: 10px !important;
}
 }
 .bloqueInfBasica {
  padding-inline: 60px;
}
.divColumna {
  justify-content: space-between;
  .clave {
    width: 40%;
  }
  .Valor {
    text-align: right;
    font-weight: 600;
  }
}
</style>
