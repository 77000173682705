// src/plugins/device-detector.js
export default {
    install(Vue) {
      const updateDeviceType = () => {
        Vue.prototype.$isMobile = window.innerWidth <= 768;
      };
  
      // Inicializar la propiedad
      updateDeviceType();
  
      // Agregar event listener para cambios de tamaño de la ventana
      window.addEventListener('resize', updateDeviceType);
  
      // Remover event listener cuando la instancia de Vue sea destruida
      Vue.mixin({
        beforeDestroy() {
          window.removeEventListener('resize', updateDeviceType);
        }
      });
    }
  };
  