<template>
  <div class="informacionCliente" v-if="servicio" style="padding-top: 48px">
    <div
      class="d-flex flex-row"
      style="align-items: center; align-items: center;"
      :style="!$isMobile?{'padding-inline': '59px'}:{'padding-inline': '10px'}"
    >
      <div class="d-flex flex-column">
        <strong style="font-size: 17px">Datos de facturación del servicio</strong>
      </div>
    </div>
    <div class="d-flex flex-column" :style="!$isMobile?{'padding-inline': '59px'}:{'padding-inline': '10px'}">
      <br />
      <span style="font-size: 13px; color: #3f51b5"
        >Distancia de la trayectoria del servicio</span
      >
      <strong style="font-size: 19px">{{ servicio.distance }}</strong>
    </div>
    <br />
    <div class="bloqueInfBasica mt-2" v-if="servicio.precios_servicio">
      <div class="d-flex flex-row" style="justify-content: space-between">
        <div class="clave">Precio del servicio</div>
        <div class="Valor" style="    font-size: 24px;">
          <small style="font-weight: 800; margin-right: 3px; font-size: 10px">EUR</small
          >{{ servicio.precios_servicio.precio }}
        </div>
      </div>

      <div
        class="divColumna d-flex mt-2"
        style="justify-content: space-between"
        v-if="servicio.urgente"
      >
          <div class="clave d-flex flex-column">
            <div>Importe urgente</div>
          </div>
          <div class="Valor" style="    font-size: 24px;">
            <small style="font-weight: 800; margin-right: 3px; font-size: 10px">EUR</small
            >{{ $fn.formatoPrecio(servicio.precios_servicio?.precio_urgente) }}
          </div>
      </div>
      <div  class="d-flex flex-row" style="justify-content: space-between"  v-if="servicio.urgente">
        <div class="clave">TOTAL</div>
        <div class="Valor" style="    font-size: 24px;">
          <small style="font-weight: 800; margin-right: 3px; font-size: 10px">EUR</small
          >{{ $fn.formatoPrecio(servicio.precios_servicio?.total) }}
        </div>
      </div>

      <hr v-if="servicio.tipo_servicio == 'ITV ENTREGA'">
      
      <div v-if="servicio.tipo_servicio == 'ITV ENTREGA'">
         <strong>Importe servicio ITV</strong>
      </div>
      

      <div   class="d-flex flex-row" style="justify-content: space-between">
        <div class="clave">Importe Base</div>
        <div class="Valor" style="    font-size: 24px;">
          <small style="font-weight: 800; margin-right: 3px; font-size: 10px">EUR</small
          >{{ servicio.importe_base?$fn.formatoPrecio(servicio.importe_base):"No agregado" }}
        </div>
      </div>

      <div   class="d-flex flex-row" style="justify-content: space-between">
        <div class="clave">Importe de tasas</div>
        <div class="Valor" style="    font-size: 24px;">
          <small style="font-weight: 800; margin-right: 3px; font-size: 10px">EUR</small
          >{{ servicio.importe_tasas?$fn.formatoPrecio(servicio.importe_tasas):"No agregado" }}
        </div>
      </div>


      <div   class="d-flex flex-row" style="justify-content: space-between">
        <div class="clave">Total</div>
        <div class="Valor" style="    font-size: 24px;">
          <small style="font-weight: 800; margin-right: 3px; font-size: 10px">EUR</small
          >{{ servicio.importe_total_itv?$fn.formatoPrecio(servicio.importe_total_itv):"No agregado" }}
        </div>
      </div>


    </div>
    <div v-else style="padding-inline: 59px; color: #ff9800; margin-top: 20px">
      <strong>El servicio no tiene facturación.</strong>
    </div>
  </div>
</template>
<script>
export default {
  props: ["servicio"],
};
</script>
<style lang="scss" scope>
@media(max-width:768px){
  .bloqueInfBasica {
  padding-inline: 10px !important;
}
 }
 .bloqueInfBasica {
  padding-inline: 60px;
}
.divColumna {
  justify-content: space-between;
  .clave {
    width: 40%;
  }
  .Valor {
    text-align: right;
    font-size:18px;
    font-weight: 600;
  }
}
</style>
