<template>
<div class="informacionBasica">
    <div class="listBotones d-flex flex-row" :style="!$isMobile?{'padding-inline':'30px'}:{'padding-inline':'10px'}">
        <div class="item">
            <div class="clave" v-if="!$isMobile">Tipo de servicio</div>
            <div class="Valor">{{ servicio.tipo_servicio }}</div>
        </div>
        <div class="item">
            <div class="clave" v-if="!$isMobile">Distancia en (KM)</div>
            <div class="Valor">{{ servicio.distance }}</div>
        </div>
        <div class="item">
            <div class="clave" v-if="!$isMobile">Precio del servicio</div>

            <div class="Valor d-flex flex-row">
                <div>
                    <small style="font-size: 10px">EUR </small>{{ $fn.formatoPrecio(Number(servicio.precio)) }}
                </div>
                <a v-if="!$isMobile" class="ml-3" href="#" @click.prevent="$parent.menu = 5">
                    Detalles factura
                </a>
            </div>
        </div>

        <div class="item" style="float: right; text-align: right; width: 24%; margin-right: 0 !important">
        </div>
    </div>
    <div class="d-flex flex-column" style="border-top: 1px solid #eee; margin-top: 14px; padding-top: 14px">

        <small v-if="servicio?.creador" style="text-transform: uppercase;
    margin-left: 29px;
    margin-bottom: 23px;
    font-weight: 700;">Creador: <span style="color: #3F51B5; font-weight: 700;">{{ servicio?.creador?.name }} </span></small>


        <div class="d-flex flex-column" :style="!$isMobile?{'padding-inline':'30px'}:{'padding-inline':'10px'}" style=" margin-bottom: 14px">
            <small>Origen</small>
            <div class="d-flex flex-row" style="align-items: center">
                <img src="/img/carrito.png" alt="" style="width: 40px" class="img-fluid" />
                <strong style="font-size: 18px">{{
            JSON.parse(servicio.origin_google).description
          }}</strong>
            </div>

            <div class="iconFilter d-flex flex-row btn btn-default" v-if="
            $isMobile
          " style="
            border: 1px solid;
            margin-top: 10px;
            justify-content: center;
            align-items: center;
            margin-inline: 15px;
          " @click.prevent="openGoogleMap(servicio)">
                <small>Ver en el mapa</small>
                <i class="fa-sharp fa-solid fa-location-dot ml-3"></i>
            </div>

        </div>

        <div class="d-flex flex-column" :style="!$isMobile?{'padding-inline':'30px'}:{'padding-inline':'10px'}" style=" margin-bottom: 14px">
            <small>Destino</small>
            <div class="d-flex flex-row" style="align-items: center">
                <img src="/img/carrito.png" alt="" style="width: 40px" class="img-fluid" />
                <strong style="font-size: 18px">{{
            JSON.parse(servicio.destino_google).description
          }}</strong>
            </div>

        </div>
    </div>

    <div>
        <Timeline :title="'Fecha del servicio ' + moment(servicio.fecha_servicio).format('DD/MM/YYYY') + ' Horario ' + servicio.desde + ' - ' + servicio.hasta" :events="events" />
    </div>
   
</div>
</template>

<script>
import Timeline from "./timeLineComponent.vue";

export default {
    components: {
        Timeline,
    },
    props: ["servicio"],
    data() {
        return {
            activeIndex: 1, // Indice del evento actual
            events: []
        };
    },
    created() {
        this.generateEvents();
    },

    methods: {

        async openGoogleMap(item) {
            const location = JSON.parse(item.origin_google);
            const placeId = location.place_id;
            const datos = {
                placeId: location.place_id,
                key: "AIzaSyCWJVaVgi7p7by3vsMC_8EUM3z3z4xmvak",
            };

            let url = this.$base.dominio + "/place/details";

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(datos),
                });

                const data = await response.json();

                if (data.status === "OK") {
                    const place = data.result;
                    const latitude = place.geometry.location.lat;
                    const longitude = place.geometry.location.lng;

                    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
                    window.open(googleMapsUrl, "_blank");
                } else {
                    console.error("Error fetching place details:", data.status);
                }
            } catch (error) {
                console.error("Error fetching place details:", error);
            }
        },

        generateEvents() {
            const serviceStages = [
                { key: 'fecha_inicio_trayecto', status: 'Inicio de trayecto del servicio', description: 'Trayecto iniciado', defaultMessage: 'Sin especificar' },
                { key: 'fecha_recogida', status: 'En recogida', description: 'Vehículo recogido por el conductor', defaultMessage: 'Sin especificar' },
                { key: 'fecha_entrega_conductor', status: 'En entrega', description: 'El conductor entregó el vehículo', defaultMessage: 'Sin especificar' },
                // { key: 'fecha_entregado_conductor', status: 'Entregado', description: 'El conductor marcó como entregado el vehículo', defaultMessage: 'Sin especificar' },
                { key: 'fecha_servicio_finalizado', status: 'Servicio finalizado', description: 'Servicio terminado con éxito', defaultMessage: 'Sin especificar' }
            ];

            this.events = serviceStages.map(stage => {
                const date = this.servicio[stage.key];
                return {
                    state: date ? 'proceso' : 'gris',
                    status: stage.status,
                    date: date ? this.moment(date).format('DD/MM/YYYY HH:mm') : 'Sin especificar',
                    description: date ? stage.description : '',
                };
            });

            // Marcar el último evento con fecha como 'finish'
            const lastEventWithDate = this.events.reverse().find(event => event.date !== 'Sin especificar');
            if (lastEventWithDate) {
                lastEventWithDate.state = 'finish';
            }
            this.events.reverse(); // Volver a invertir el array a su orden original
        },

        mostrarServicio(servicio, proceso) {
            this.$store.state.mostrarAsignar = servicio;
            this.$store.state.proceso_asignar = proceso;
            $("#asignarConductorServicio").modal("show");
        },
    },
};
</script>

<style lang="scss">
@media(max-width:768px) {
    .bloqueInfBasica {
        padding-inline: 10px !important;
    }
}

.bloqueInfBasica {
    padding-inline: 60px;
}

.listBotones {
    .item {
        margin-right: 40px;
    }

    .clave {
        font-size: 12px;
    }

    .Valor {
        font-weight: 600;
    }
}
</style>
