<template>
  <div class="informacionGoogleMap" v-if="servicio" style="    margin-top: -15px;">
    <div class="bloqueInfBasica mt-4 p-0">
      <div class="container-fluid">
        <div id="googleMap" style="height: 350px"></div>
        <!-- <p>Distancia: {{ distance }}</p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["servicio"],
  data() {
    return {
      map: null,
      directionsService: null,
      directionsRenderer: null,
      distance: null,
      
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      this.map = new google.maps.Map(document.getElementById("googleMap"), {
        zoom: 14,
        center: { lat: 40.4168, lng: -3.7038 }, // Coordenadas de Madrid, España
        disableDefaultUI: true, // Oculta los controles de navegación
        scrollwheel: false, // Deshabilita el zoom con la rueda del mouse
        gestureHandling: 'none' // Deshabilita el zoom y paneo con gestos
      });
      this.directionsService = new google.maps.DirectionsService();
      this.directionsRenderer = new google.maps.DirectionsRenderer({ map: this.map });

      // Llamar a la función para obtener las coordenadas de origen y destino
      this.getCoordinates();
    },
    getCoordinates() {

      const service = new google.maps.places.PlacesService(this.map);

      // Obtener coordenadas de origen
      service.getDetails(
        {
          placeId:  JSON.parse(this.servicio.origin_google).place_id,
        },
        (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            this.origin = place.geometry.location.toJSON();
            this.calcRoute();
          }
        }
      );

      // Obtener coordenadas de destino
      service.getDetails(
        {
          placeId: JSON.parse(this.servicio.destino_google).place_id,
        },
        (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            this.destination = place.geometry.location.toJSON();
            this.calcRoute();
          }
        }
      );
    },
    calcRoute() {
      if (this.origin && this.destination) {
        this.directionsService.route(
          {
            origin: this.origin,
            destination: this.destination,
            travelMode: "DRIVING",
          },
          (response, status) => {
            if (status === "OK") {
              this.directionsRenderer.setDirections(response);
              this.distance = response.routes[0].legs[0].distance.text;
            } else {
              window.alert("Error al calcular la ruta, por favor inténtalo de nuevo");
            }
          }
        );
      }
    },
  },
};
</script>

<style scoped>
/* Estilos de tu componente */
</style>
