import { io } from 'socket.io-client';

class SocketioService {
    socket;
    constructor() {}

    setupSocketConnection() {
        // this.socket = io('http://localhost:3000');
        this.socket = io('https://realtime4drive.unocomaseis.com');
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }


}

export default new SocketioService();
