import { dominio } from '../../config/base';
import  axios  from 'axios';

export default {
    async retriveRequestCount({commit}, user_id){
        let url =  dominio + '/service/retrive-requests-count';
        const sendData = {
            user_id: user_id
        }
        try{
            const response  = await axios.post(url, sendData);
            commit('SET_REQUEST', response.data);
            return response.data;
        }catch(e){
            console.log(e);
            return [];
        }finally{
            console.log("fin de la request");
        }

    }
}