<template>
  <div>
    <ul
      class="d-flex"
      style="
        margin-left: 0px;
        padding-left: 0px;
        flex-flow: wrap;
        margin-top: 15px;
        padding-inline: 15px;
        padding-bottom: 0;
        margin-bottom: 0;
      "
    >
      <li
        class="menuItem d-flex flex-row"
        :style="$parent.menu == 1 ? { color: '#88b300', 'font-weight': 'bold' } : {}"
        @click.prevent="$parent.menu = 1"
      >
        <i><i class="iconMenu fa-duotone mr-1 fa-circle-info"></i></i> Información básica
      </li>
      <li
        class="menuItem d-flex flex-row"
        :style="$parent.menu == 2 ? { color: '#88b300', 'font-weight': 'bold' } : {}"
        @click.prevent="$parent.menu = 2"
      >
        <i><i class="iconMenu fa-solid fa-user-group mr-1"></i></i> Conductor y cliente
      </li>
      <li
        class="menuItem d-flex flex-row"
        :style="$parent.menu == 3 ? { color: '#88b300', 'font-weight': 'bold' } : {}"
        @click.prevent="$parent.menu = 3"
      >

        <i><i class="iconMenu fa-duotone mr-1 fa-cars"></i></i> Taller y vehículo
      </li>
      <li
        class="menuItem d-flex flex-row"
        :style="$parent.menu == 4 ? { color: '#88b300', 'font-weight': 'bold' } : {}"
        @click.prevent="$parent.menu = 4"
      >
        <i><i class="iconMenu fa-solid mr-1 fa-receipt"></i></i> Evidencias
      </li>
      
    </ul>
  </div>
</template>
<script>
export default {
  mounted() {},
};
</script>
<style lang="scss" scoped>

.menuItem {
  cursor: pointer;
  background: #ffffff;
  padding: 4px;
  border-radius: 10px;
  padding-inline: 12px;
  box-shadow: 0px 0px 2px 2px #eee;
  margin-right: 10px;
  margin-bottom: 15px;
  &:hover {
    transition: 0.3s;
    background: #f6f6f6;
  }
}
</style>
