<template>
  <div class="timeline-container" style="padding-inline: 20px">
    <h2 style="font-size: 20px">{{ title }}</h2>
    <div class="timeline">
      <div
        v-for="(event, index) in events"
        :key="index"
        :class="[
          'timeline-item',
          {
            'timeline-item--active': isActive(index),
            'timeline-item--grey': isGrey(index),
          },
        ]"
      >
        <div class="timeline-marker"></div>
        <div class="timeline-content">
          <h3 style="font-size: 16px">{{ event.status }}</h3>
          <p style="font-size: 14px">
            {{ event.date }} | <span style="color: #03a9f4">{{ event.description }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Timeline",
  props: {
    title: {
      type: String,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
  },
  methods: {
    isActive(index) {
      return (
        this.events[index].state === "proceso" || this.events[index].state === "finish"
      );
    },
    isGrey(index) {
      return this.events[index].state === "gris";
    },
  },
};
</script>

<style>
.timeline-container {
  margin: 20px;
  font-family: Arial, sans-serif;
}

.timeline {
  position: relative;
  padding: 20px 0;
}

.timeline-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.timeline-item:last-child {
  margin-bottom: 0;
}

.timeline-marker {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #d3d3d3;
  border: 3px solid #fff;
  z-index: 2;
}

.timeline-item--active .timeline-marker {
  background-color: #4caf50;
}

.timeline-item--grey .timeline-marker {
  background-color: #d3d3d3;
}

.timeline-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 5px;
  width: 2px;
  height: 100%;
  background-color: #d3d3d3;
  z-index: 1;
}

.timeline-item--active::before {
  background-color: #4caf50;
}

.timeline-item--grey::before {
  background-color: #d3d3d3;
}

.timeline-content {
  margin-left: 20px;
}

.timeline-item--active .timeline-content h3 {
  color: #4caf50;
}
</style>
