<template>
  <div class="informacionData" v-if="servicio.taller" style="padding-top: 20px">
    <div
      class="d-flex flex-row"
      v-if="servicio.fecha_inicio_trayecto"
      style="align-items: center; align-items: center; padding-inline: 20px"
    >
      <div class="d-flex flex-column">
        <strong style="font-size: 17px; color: #4caf50"
          >Fecha inicio de trayectoria del servicio</strong
        >
        <div>
          {{ moment(servicio.fecha_inicio_trayecto).format("DD/MM/YYYY - HH:m") }}
        </div>
        <br />
        <small style="color: #03a9f4">Observación del conductor</small>
        <p v-if="servicio.ob_inicio_trayecto && servicio.ob_inicio_trayecto != 'null'">
          {{ servicio.ob_inicio_trayecto }}
        </p>
        <p v-else>El conductor no agrego una observación</p>
      </div>
    </div>
    <div
      v-else
      class="mensajeError"
      style="font-size: 20px; margin: auto; text-align: center"
    >
      Sin evidencias del servicio
    </div>

    <div v-if="servicio.fecha_recogida">
      <div>
        <hr />
        <div
          class="d-flex flex-row"
          v-if="servicio.fecha_recogida"
          style="align-items: center; align-items: center; padding-inline: 20px"
        >
          <div class="d-flex flex-column">
            <strong style="font-size: 17px; color: #4caf50"
              >Fecha de recogida del vehículo</strong
            >
            <div>
              {{ moment(servicio.fecha_recogida).format("DD/MM/YYYY - HH:m") }}
            </div>
            <br />
            <small style="color: #03a9f4">Observación del conductor</small>
            <p v-if="servicio.ob_recogida && servicio.ob_recogida != 'null'">
              {{ servicio.ob_recogida }}
            </p>
            <p v-else>El conductor no agrego una observación</p>
          </div>
        </div>

        <div class="container" v-if="servicio.fecha_recogida">
          <strong class="ml-1">Fotos del proceso de recogida</strong>
          <div class="row p-3">
            <template v-for="(image, index) in servicio.image_cars">
              <div
                class="col-lg-2 col-12 mb-2 p-1"
                :key="index"
                v-if="image.type == 'photo' && image.proceso == 'RECOGIDA'"
              >
                <img
                  :src="$base.server + 'images/' + image.file"
                  alt=""
                  class="img-fluid"
                  style="border: 1px solid #eee; min-height: 165px"
                />
                <a
                  :href="$base.server + 'images/' + image.file"
                  :download="image.media_key"
                  target="_blank"
                  class="download-icon"
                >
                  <div
                    class="d-flex"
                    style="
                      width: 30px;
                      height: 30px;
                      background: white;
                      justify-content: center;
                      align-items: center;
                      border-radius: 50px;
                      position: absolute;
                      bottom: 18px;
                      right: 15px;
                    "
                  >
                    <i class="fa-regular fa-expand" style="color:#333"></i>
                  </div>
                </a>
              </div>

              <div
                class="col-lg-2 col-12 mb-2 p-1"
                :key="index"
                v-else-if="image.type === 'video' && image.proceso === 'RECOGIDA'"
              >
                <!-- Mostrar video si es tipo 'video' y proceso 'RECOGIDA' -->
                <video
                  controls
                  class="img-fluid"
                  style="border: 1px solid #eee; min-height: 177px"
                >
                  <source :src="$base.server + 'images/' + image.file" type="video/mp4" />
                  <!-- Agrega más formatos de video según sea necesario -->
                  Tu navegador no soporta video HTML5.
                </video>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div v-if="servicio.fecha_recogida">
      <div>
        <hr />
        <div
          class="d-flex flex-row"
          v-if="servicio.fecha_entrega_conductor"
          style="align-items: center; align-items: center; padding-inline: 20px"
        >
          <div class="d-flex flex-column">
            <strong style="font-size: 17px; color: #4caf50"
              >Fecha de entrega del vehículo</strong
            >
            <div>
              {{ moment(servicio.fecha_entrega_conductor).format("DD/MM/YYYY - HH:m") }}
            </div>
            <br />
            <small style="color: #03a9f4">Observación del conductor</small>
            <p
              v-if="
                servicio.ob_fecha_entregado_conductor &&
                servicio.ob_fecha_entregado_conductor != 'null'
              "
            >
              {{ servicio.ob_fecha_entregado_conductor }}
            </p>
            <p v-else>El conductor no agrego una observación</p>
          </div>
        </div>

        <div class="container" v-if="servicio.fecha_entrega_conductor">
          <strong class="ml-1">Fotos del proceso de entrega</strong>
          <div class="row p-3">
            <template v-for="(image, index) in servicio.image_cars">
              <div
                class="col-lg-2 col-12 mb-2 p-1"
                :key="index"
                v-if="image.type == 'photo' && image.proceso == 'ENTREGA'"
              >
                <img
                  :src="$base.server + 'images/' + image.file"
                  alt=""
                  class="img-fluid"
                  style="
                    border: 1px solid #eee;
                    min-height: 165px;
                    background-color: #eee;
                  "
                />
                <a
                  :href="$base.server + 'images/' + image.file"
                  :download="image.media_key"
                  target="_blank"
                  class="download-icon"
                >
                  <div
                    class="d-flex"
                    style="
                      width: 30px;
                      height: 30px;
                      background: white;
                      justify-content: center;
                      align-items: center;
                      border-radius: 50px;
                      position: absolute;
                      bottom: 18px;
                      right: 15px;
                    "
                  >
                    <i class="fa-regular fa-expand" style="color:#333"></i>
                  </div>
                </a>
              </div>
              <!-- Mostrar video si es tipo 'video' y proceso 'RECOGIDA' -->
              <div
                class="col-lg-2 col-12 mb-2 p-1"
                :key="index"
                v-else-if="image.type === 'video' && image.proceso === 'ENTREGA'"
              >
                <video
                  controls
                  class="img-fluid"
                  style="border: 1px solid #eee; min-height: 177px"
                >
                  <source :src="$base.server + 'images/' + image.file" type="video/mp4" />
                  <!-- Agrega más formatos de video según sea necesario -->
                  Tu navegador no soporta video HTML5.
                </video>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div v-if="servicio.fecha_entrega_conductor">
      <div>
        <hr />
        <div
          class="d-flex flex-row"
          v-if="servicio.fecha_servicio_finalizado"
          style="align-items: center; align-items: center; padding-inline: 20px"
        >
          <div class="d-flex flex-column">
            <strong style="font-size: 17px; color: #4caf50">Servicio finalizado </strong>
            <div>
              {{ moment(servicio.fecha_servicio_finalizado).format("DD/MM/YYYY - HH:m") }}
            </div>
            <br />
            <small style="color: #03a9f4">Observación del conductor</small>
            <p
              v-if="
                servicio.ob_fecha_servicio_finalizado &&
                servicio.ob_fecha_servicio_finalizado != 'null'
              "
            >
              {{ servicio.ob_fecha_servicio_finalizado }}
            </p>
            <p v-else>El conductor no agrego una observación</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="servicio && servicio.firma_car && servicio.firma_car[0]">
      <div>
        <hr />
        <div
          class="d-flex "
          :class="{'flex-row': !$isMobile, 'flex-column': $isMobile}"
          style="
            align-items: center;
            align-items: center;
            padding-inline: 20px;
            justify-content: space-around;
          "
        >
          <div class="d-flex flex-column" style="width: 271px">
            <strong style="font-size: 17px; color: #4caf50"
              >Firma de la persona que entrega el vehículo</strong
            >
            <br />
            <img
              style="width: 200px; margin: auto"
              :src="$base.server + servicio.firma_car[0]?.foto_firma"
              alt=""
              class="img-fluid"
            />
          </div>

          <div class="d-flex flex-column" style="width: 271px">
            <strong style="font-size: 17px; color: #4caf50"
              >Firma del responsable de recepción del vehículo</strong
            >
            <br />
            <img
              style="width: 200px; margin: auto"
              :src="$base.server + servicio.firma_car[1]?.foto_firma"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="servicio.fecha_fallida">
      <div>
        <hr />
        <div
          class="d-flex flex-row"
          v-if="servicio.fecha_fallida"
          style="align-items: center; align-items: center; padding-inline: 20px"
        >
          <div class="d-flex flex-column">
            <strong style="font-size: 17px; color: red"
              >Servicio fue marcado como fallido
            </strong>
            <div>
              {{ moment(servicio.fecha_fallida).format("DD/MM/YYYY - HH:m") }}
            </div>
            <br />
            <small style="color: #03a9f4">Observación</small>
            <p v-if="servicio.ob_fallida && servicio.ob_fallida != 'null'">
              {{ servicio.ob_fallida }}
            </p>
            <p v-else>No se agrego una observación</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 
    <div class="bloqueInfBasica mt-2" v-if="servicio.estado == 'Terminado'">
      <div class="divColumna d-flex mt-2">
        <div class="clave">Fecha finalización</div>
        <div class="Valor" style="">
          {{ moment(servicio.fecha_servicio_finalizado).format("DD/MM/YYYY") }}
        </div>
      </div>

      <div class="divColumna d-flex mt-2">
        <div class="clave">Horario de finalización</div>
        <div class="Valor" style="">
          <div>{{ moment(servicio.fecha_servicio_finalizado).format("HH:mm") }}</div>
        </div>
      </div>

      <br />
      <a
        :href="$base.dominio + '/generate/report/' + servicio.id + '/service'"
        target="_blank"
      >
        <button class="btn btn-default" style="background: #8bc34a">
          Descargar reporte <i class="fa-solid fa-file-pdf ml-2"></i>
        </button>
      </a>
    </div>
    <div v-else style="padding-inline: 59px; color: #ff9800; margin-top: 20px">
      <strong>El servicio no a finalizo.</strong>
    </div>

    <hr /> -->

    <!-- <div
      class="d-flex flex-row"
      style="align-items: center; align-items: center; padding-inline: 59px"
    >
      <div class="d-flex flex-column">
        <strong style="font-size: 17px">Inicio de trayecto</strong>
      </div>
    </div> -->

    <!-- <div class="bloqueInfBasica mt-2" v-if="servicio.fecha_inicio_trayecto">
      <div class="divColumna d-flex mt-2">
        <div class="clave">Fecha</div>
        <div class="Valor" style="">
          {{ moment(servicio.fecha_inicio_trayecto).format("DD/MM/YYYY") }}
        </div>
      </div>

      <div class="divColumna d-flex mt-2">
        <div class="clave">Hora</div>
        <div class="Valor" style="">
          <div>{{ moment(servicio.fecha_inicio_trayecto).format("HH:mm") }}</div>
        </div>
      </div>

      <br />
     
    </div> -->
    <!-- <div v-else style="padding-inline: 59px; color: #ff9800; margin-top: 20px">
      <strong>El servicio todavía no inicia</strong>
    </div> -->
    <!-- 
    <hr />
    <div
      class="d-flex flex-row"
      style="align-items: center; align-items: center; padding-inline: 59px"
    >
      <div class="d-flex flex-column">
        <strong style="font-size: 17px">Fecha de solicitud del servicio</strong>
      </div>
    </div> -->

    <!-- <div class="bloqueInfBasica mt-2">
      <div class="divColumna d-flex mt-2">
        <div class="clave">Fecha servicio</div>
        <div class="Valor" style="">
          {{ moment(servicio.fecha_servicio).format("DD/MM/YYYY") }}
        </div>
      </div>

      <div class="divColumna d-flex mt-2" v-if="servicio.hora">
        <div class="clave">Horario del servicio</div>
        <div class="Valor" style="">
          <div>{{ servicio.hora.hora_inicio }} - {{ servicio.hora.hora_fin }}</div>
          <div v-if="servicio.hora?.urgente" style="color: #d72424">Urgente</div>
        </div>
      </div>

      <br />
      <div class="divColumnaLarge flex-column d-flex mt-2">
        <div class="clave"><strong>Observaciones:</strong></div>
        <br />
        <div class="Valor">
          {{
            servicio.observaciones && servicio.observaciones != "null"
              ? servicio.observaciones
              : "No existen observaciones"
          }}
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  props: ["servicio"],
};
</script>
<style lang="scss" scope>
.bloqueInfBasica {
  padding-inline: 60px;
}
.divColumna {
  justify-content: space-between;
  .clave {
    width: 40%;
  }
  .Valor {
    text-align: right;
    font-weight: 600;
  }
}
</style>
