<template>
  <div id="app">
    <Loader v-if="$store.state.loader" />
    <router-view />
  </div>
</template>

<script>
import Loader from "@/components/Laders/Loader.vue";
import SocketioService from "./services/socketio.service.js";
export default {
  name: "App",
  created() {
    SocketioService.setupSocketConnection();
  },
  beforeUnmount() {
    SocketioService.disconnect();
  },
  mounted() {
    if (this.$store.state.user) {
      SocketioService.socket.on("notificacion-servicio", (msg) => {
        if (msg.type == "CAMBIO-ESTADO") {
          if (msg.datos?.concesionario_id == this.$store.state.user.id) {
            localStorage.setItem("notificacionesActive", JSON.stringify(true));
            this.$store.state.notificacionesActive = true;
            this.$fn.Notifications(this.$store.state.user.id);
          }
          if (msg.datos?.taller_id == this.$store.state.user.id) {
            localStorage.setItem("notificacionesActive", JSON.stringify(true));
            this.$store.state.notificacionesActive = true;
            this.$fn.Notifications(this.$store.state.user.id);
          }
          if (this.$store.state.user.type == "admin") {
            localStorage.setItem("notificacionesActive", JSON.stringify(true));
            this.$store.state.notificacionesActive = true;
            this.$fn.Notifications(this.$store.state.user.id);
          }

          if (this.$store.state.user.type == "coordinador") {
            const concesionarios = this.$store.state.user.concesionarios || [];
            const concesionarioExists = concesionarios.some(
              (c) => c.concesionario_id == msg.datos?.concesionario_id
            );

            if (concesionarioExists) {
              this.$fn.Notifications(this.$store.state.user.id);
              localStorage.setItem("notificacionesActive", JSON.stringify(true));
              this.$store.state.notificacionesActive = true;
            }
          }
        }

        if (msg.type == this.$store.state.user.type) {
          if (msg.type == "coordinador") {
            console.log(msg.concesionario_id, " - - USER COORDINADOR");
            const concesionarios = this.$store.state.user.concesionarios || [];
            const concesionarioExists = concesionarios.some(
              (c) => c.concesionario_id == msg.concesionario_id
            );

            if (concesionarioExists) {
              this.$fn.Notifications(this.$store.state.user.id);
              localStorage.setItem("notificacionesActive", JSON.stringify(true));
              this.$store.state.notificacionesActive = true;
            }
          } else {
            if (msg.user_id == this.$store.state.user.id) {
              console.log(msg.user_id, " - - USER", msg.type);
              localStorage.setItem("notificacionesActive", JSON.stringify(true));
              this.$store.state.notificacionesActive = true;
              this.$fn.Notifications(this.$store.state.user.id);
            }
          }
        }

       
      });
    } else {
      console.log("user no registrado");
    }
  },
  components: {
    Loader,
  },
};
</script>

<style lang="scss">
// Boton redondo global
.iconBoton {
  height: 34px;
  width: 34px;
  background: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  box-shadow: 0px 0px 5px 2px #eeeeeeba;
  margin-inline: 9px;
}

.mx-calendar-content .cell.active {
  color: #fff !important;
  background-color: #1284e7;
}

.card {
  box-shadow: 0px 0px 6px 3px #0000000f;
}
.swal2-actions {
  flex-direction: row-reverse !important;
}
.swal2-title {
  font-size: 22px;
}
div:where(.swal2-icon).swal2-warning {
  border-color: #e91e63 !important;
  color: #e91e63 !important;
  width: 60px !important;
  height: 60px !important;
}

.form {
  input,
  .form-control {
    height: 50px !important;
  }
}
</style>
