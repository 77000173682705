<template>
<!-- Sidebar -->
<ul style="transition: 0.4s" v-animate-css="'slideInLeft'" class="navbar-nav sidebar sidebar-dark accordion" :class="{ toggled: active }" id="accordionSidebar">
    <!-- Sidebar - Brand -->

    <a class="sidebar-brand" href="#" @click.prevent="irHome()" :style="$store.state.screen ? { 'padding-inline': 0 } : { padding: '1.5rem 1rem' }">
        <img v-if="$store.state.screen" src="/img/marca/logo.png" v-animate-css="'slideInLeft'" alt="" style="transition: 0.4s; width: 146px; float: left; margin-left: 15px" />
        <img v-if="!$store.state.screen" src="/img/marca/logo2.png" alt="" v-animate-css="'slideInLeft'" style="transition: 0.4s; width: 76px; float: left" />
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0" />

    <!-- Divider -->
    <!-- <hr class="sidebar-divider" /> -->

    <!-- Heading -->
    <div class="sidebar-heading" style="color: rgb(121 121 121); margin-top: 40px">
        Opciones de sistema
    </div>

    <!-- Nav Item - Dashboard -->
    <li class="nav-item" @click.prevent="ruta('Home')" v-if="$store.state?.user?.type == 'admin'">
        <a class="nav-link" href="index.html" :class="{
          active: $route.name == 'Home',
          activeFondo: $route.name == 'Home',
        }">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span></a>
    </li>

    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item" @click.prevent="ruta('Services')">
        <a class="nav-link" href="#" :class="{
          active: $route.name == 'Services',
          activeFondo:
            $route.name == 'Services' ||
            $route.name == 'createServices' ||
            $route.name == 'updateServices',
        }">
            <!-- <i class="fas fa-fw fa-chart-area"></i> -->
            <i class="fa-regular fa-fw fa-car" :class="{
            active:
              $route.name == 'Services' ||
              $route.name == 'createServices' ||
              $route.name == 'updateServices',
          }"></i>
            <span>Servicios</span></a>
    </li>

    <li class="nav-item" @click.prevent="ruta('serviceRequest')" v-if="$store.state.user.type == 'admin' || $store.state.user.type == 'coordinador'">
        <a class="nav-link" href="#" :class="{
          active: $route.name == 'serviceRequest',
          activeFondo:
            $route.name == 'serviceRequest'
        }">
            <!-- <i class="fas fa-fw fa-chart-area"></i> -->
            <i class="fa-solid fa-bell-on" :class="{
              active:
              $route.name == 'serviceRequest',  danger: requests_count > 0,
              heartbeat: requests_count > 0 // Aplicar la animación solo si 'danger' está activo
          }"></i>
            <span>Solicitudes</span></a>
    </li>

    <!-- Nav Item - Utilities Collapse Menu -->
    <li class="nav-item" @click.prevent="ruta('Dealers')" v-if="$store.state.user.type == 'admin'">
        <a class="nav-link" href="charts.html" :class="{
          active: $route.name == 'Dealers' || $route.name == 'dealersManagement',
          activeFondo: $route.name == 'Dealers' || $route.name == 'dealersManagement',
        }">
            <i class="fa-regular fa-fw fa-car-garage" :class="{
            active: $route.name == 'Dealers' || $route.name == 'dealersManagement',
          }"></i>
            <span>Concesionarios</span></a>
    </li>

    <div v-if="$store.state.user?.type == 'concesionario'" class="d-flex flex-column" style="justify-content:center">
        <br />
        <div style="height: 1px; background:#eee; width:90%; margin: auto;"></div>
        <br />
    </div>

    <!-- Nav Item - Charts -->
    <li class="nav-item" @click.prevent="ruta('Taller')" v-if="$store.state.user.type != 'taller' && $store.state.user.type != 'coordinador' && $store.state.user.type != 'admin'">
        <a class="nav-link" href="#" :class="{
          active: $route.name == 'Taller',
          activeFondo: $route.name == 'Taller',
        }">
            <i class="fa-regular fa-fw fa-car-wrench"></i>
            <span v-if="$store.state.user?.type == 'concesionario'">Mis talleres</span>
        </a>
    </li>

    <!-- Nav Item - Charts -->
    <li class="nav-item" @click.prevent="ruta('Conductores')" v-if="$store.state.user.type == 'admin'">
        <a class="nav-link" href="#" :class="{
          active: $route.name == 'Conductores',
          activeFondo: $route.name == 'Conductores',
        }">
            <i class="fa-sharp fa-regular fa-id-card"></i>
            <span>Conductores</span></a>
    </li>
    <li class="nav-item" @click.prevent="ruta('Coordinador')" v-if="$store.state.user.type == 'admin'">
        <a class="nav-link" href="#" :class="{
          active: $route.name == 'Coordinador',
          activeFondo: $route.name == 'Coordinador',
        }">
            <i class="fa-regular fa-fw fa-users-cog"></i>
            <span>Coordinadores</span></a>
    </li>

    <!-- Nav Item - Charts -->

    <li class="nav-item" @click.prevent="ruta('adminConcesionarioInvoices')" v-if="$store.state.user.type == 'concesionario'">
        <a class="nav-link" href="#" :class="{
          active: $route.name == 'adminConcesionarioInvoices',
          activeFondo: $route.name == 'adminConcesionarioInvoices',
        }">
            <i class="fa-regular fa-fw fa-file-invoice"></i>
            <span>Facturación</span></a>
    </li>

    <li class="nav-item" @click.prevent="ruta('Invoices')" v-if="$store.state.user.type == 'admin'">
        <a class="nav-link" href="#" :class="{
          active: $route.name == 'Invoices',
          activeFondo: $route.name == 'Invoices',
        }">
            <i class="fa-regular fa-fw fa-file-invoice"></i>
            <span>Facturación</span></a>
    </li>

    <li class="nav-item" @click.prevent="ruta('Version_Apk')" v-if="$store.state.user.type == 'admin'">
        <a class="nav-link" href="#" :class="{
          active: $route.name == 'Version_Apk',
          activeFondo: $route.name == 'Version_Apk',
        }">
            <i class="fa-brands fa-android"></i>
            <span>APK</span></a>
    </li>

    <!-- Nav Item - Tables -->
    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block" />

    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
        <button @click.prevent="activateSidebar()" class="rounded-circle border-0" id="sidebarToggle"></button>
    </div>
</ul>
<!-- End of Sidebar -->
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    name: 'SidebarDashboard',
    mounted() {
        this.retriveRequestCount(this.$store.state.user?.id);
    },
    data() {
        return {
            active: false,
        };
    },
    computed:{
      ...mapState('requests', ['requests_count']),
    },
    methods: {
        ...mapActions('requests', ['retriveRequestCount']),
        irHome() {
            if(this.$store.state.user && this.$store.state.user.type == 'admin'){
              if (this.$route.name != "Home") {
                this.$router.push({ name: "Home" });
              }
            }else {
              if (this.$route.name != "Services") {
                this.$router.push({ name: "Services" });
              }
            }
           
        },
        ruta(item) {
            if (item != this.$route.name) {
                this.$router.push({ name: item });
            }
        },
        activateSidebar() {
            this.active = !this.active;
            this.$store.state.screen = !this.$store.state.screen;
        },
    },
};
</script>

<style>
.danger {
  color:red !important;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

/* Clase para activar el parpadeo/latido */
.heartbeat {
  animation: heartbeat 1.5s infinite;
}


.activeFondo {
    background: #2196f30d;
    border-radius: 10px;
}

.bloque2Active {
    background: #007cff38;
}

.navbar-nav {
    position: fixed;
    background: white;
    z-index: 100;
    border-right: 1px solid #eee;
}

.sidebar-dark .nav-item .nav-link {
    color: rgb(0 0 0 / 80%);
}

.sidebar .nav-item .nav-link span {
    font-size: 15px;
    font-weight: 500;
}

.sidebar-dark .nav-item .nav-link i {
    color: rgb(0 0 0 / 74%);
    margin-right: 10px;
    font-size: 18px;
}

.sidebar .nav-item {
    position: relative;
    /* border-bottom: 1px solid rgba(34, 46, 58, 0.1); */
    padding-block: 2px;
}

.ul {
    padding-inline: 10px;
}

.sidebar-dark #sidebarToggle::after {
    color: rgb(255 255 255) !important;
}

.sidebar-dark #sidebarToggle {
    background-color: #007cff !important;
}

.sidebar-dark .nav-item .nav-link:active,
.sidebar-dark .nav-item .nav-link:focus,
.sidebar-dark .nav-item .nav-link:hover {
    color: #007cff !important;
}

.sidebar-dark .nav-item .nav-link:active i,
.sidebar-dark .nav-item .nav-link:focus i,
.sidebar-dark .nav-item .nav-link:hover i {
    color: #007cff;
}

.active {
    color: #007cff !important;
}
</style>
