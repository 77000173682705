<template>
  <div id="wrapper" style="">
    <Sidebar v-if="!$isMobile" />
    <SidebarMobile v-if="$isMobile && $store.state.menuMobileScreen" v-animate-css="'slideInLeft'" />
    <!-- <Nav /> -->

    <div id="content-wrapper" class="d-flex flex-column" style="background: #fff">
      <div id="content" style="height: 100vh">
        <!-- Topbar -->
        <Nav />
        <!-- End of Topbar -->

        <!-- Begin Page Content -->
        <div
          class="container-fluid"
          style="background: #fff; transition: 0.4s"
          :style="displayValidate()"
        >
          <!-- Page Heading -->
          <router-view />
        </div>
        <!-- /.container-fluid -->
      </div>

      <!-- <Footer /> -->
    </div>

    <!-- Modales globales -->
    <view-request-service />
    <modal-asignar-servicio :placeholderTitle="'Buscar conductor'" />
    <!--  -->
  </div>
</template>
<script>
import Sidebar from "../../Maquetas/admin/sidebar";
import SidebarMobile from "@/Maquetas/admin/sidebarMobile";
import Nav from "../../Maquetas/admin/nav";
import ViewRequestService from '@/Screen/Servicios/viewRequestService.vue';
import ModalAsignarServicio from '@/components/Screen/Servicios/modalAsignarServicio.vue';
export default {
  components: {
    Sidebar,
    SidebarMobile,
    Nav,
    ViewRequestService,
    ModalAsignarServicio
  },

  methods: {
    displayValidate(){
      if(!this.$isMobile){
         if(this.$store.state.screen){
           return  {
                  'margin-left': '236px',
                  'margin-top': '100px',
                  'padding-right': '272px',
                }

         }else {
              return {
                  'margin-left': '112px',
                  'margin-top': '100px',
                  'padding-right': '135px',
                }
         }
      }else {
        return {
                  'margin-top': '100px',
                }
      }
    },
    functionColorState(item) {
      if (item.estado == "Solicitado") {
        return "orange";
      }
      if (item.estado == "Asignado" || item.estado == "En curso") {
        return "#009688";
      } else if (item.estado == "Terminado") {
        return "#4CAF50";
      }
      else if (item.estado == "En recogida" || item.estado == "En entrega") {
        return "rgb(217, 0, 213)";
      }
      else if (item.estado == "Fallido") {
        return "red";
      }
    },

    open() {
      $("#exampleModal").modal("show");
    },
    close() {
      $("#exampleModal").modal("hide");
    },
  },
};
</script>
