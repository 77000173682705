<template>
  <div class="modal fade" v-animate-css="'slideIn'" id="asignarConductorServicio" tabindex="-1"
  data-backdrop="static"  role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true"  style="overflow-y: scroll;">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content" style="min-height: 780px;">
        <LoaderBloque v-if="loaderBloque" mensaje="Asignando conductores al servicio" />
        <div class="modal-header">
          <h5 class="modal-title">Asignar conductor al servicio</h5>
          <button type="button" class="close" @click="$store.state.mostrarAsignar = null" data-dismiss="modal" aria-label="Close">
            <i class="fa-light fa-xmark"></i>
          </button>
        </div>
        <div class="modal-body" v-if="$store.state.mostrarAsignar">
          <!-- Informacion basica   -->
          <div class="d-flex flex-column">
            <!-- <small>Destino del servicio</small>
            <strong style="font-size: 25px;">{{JSON.parse($store.state.mostrarAsignar.destino_google)?.description}}</strong> -->

          </div>
          

          <div class="bloqueTipo d-flex flex-column">
            <small>Tipo de servicio:  <strong>{{ $store.state.mostrarAsignar.tipo_servicio }}</strong></small>
            <small>Distancia : <strong>{{ $store.state.mostrarAsignar.distance }}</strong></small>
          </div>

          <div class="form mt-3">
            <hr />
            <table-component
              :placeholderSearch="placeholderTitle"
              :url="$base.dominio + '/talleres/conductores/table'"
              :servicioConcesionario="$store.state.mostrarAsignar.concesionario_id"
              :servicioTaller="$store.state.mostrarAsignar.taller_id"
              
            >
              <template v-slot:tbody="slotProps">
              
                <ul class="list-group" v-for="item in slotProps.items" :key="item.id">
                    <template v-if="item">
                  <li
                    class="list-group-item listHover d-flex flex-row"
                    :class="{ activeSelect: conductor_id == item.id }"
                    style="cursor: pointer; align-items: center"
                    @click.prevent="select(item.id)"
                  >
                    <div class="id" style="width: 10%">
                      {{ item.id }}
                    </div>
                    <div
                      class="nombre d-flex flex-column text-truncate"
                      style=" word-wrap: break-word"
                      :style="!$isMobile?{'width': '30%'}:{'width': '60%'}"
                    >
                      <small>Nombre</small>
                      <span
                        style="
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        <strong>{{ item.name }}</strong>
                      </span>
                    </div>
                    <div
                      v-if="!$isMobile"
                      class="direccion d-flex flex-column text-truncate"
                      style="padding-left: 20px; word-wrap: break-word; max-width: 60%"
                    >
                      <small>Correo electrónico</small>
                      <span
                        style="
                          font-weight: 500;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        <span>{{ item.email }}</span>
                      </span>
                    </div>
                  </li>
                </template>
                </ul>
                </template>
                
             
            </table-component>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            style="background: #9c9c9c; border: none; color: white"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            Cerrar
          </button>
          <button
            @click.prevent="asignarConductores()"
            type="button"
            class="btn"
            :class="{ disabled: !conductor_id }"
            :disabled="!conductor_id"
            style="
              background: #007cff;
              border: none;
              font-weight: 500;
              box-shadow: #6741ea3d 0px 5px 15px 1px;
              color: white;
            "
          >
            Asignar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoaderBloque from "../../Laders/LoaderBloque.vue";
import TableComponent from "../../tableModals/table.vue";
import SocketioService from "../../../services/socketio.service";

import toastr from "toastr";
export default {
  props: ["title", "placeholderTitle", "type"],
  mounted() {},
  components: { LoaderBloque, TableComponent },
  data() {
    return {
      loaderBloque: false,
      conductor_id: null,
    };
  },
  methods: {

    

    select(id) {
      if (id == this.conductor_id) {
        this.conductor_id = null;
      } else {
        this.conductor_id = id;
      }
    },
    asignarConductores() {
      this.loaderBloque = true;

      // Guardamos el taller
      let url = this.$base.dominio + "/asignar/servicio/conductor";

      const formData = new FormData();
      formData.append("id", this.$store.state.mostrarAsignar.id);
      formData.append("user_id", this.$store.state.user.id);
      formData.append("proceso_asignar", this.$store.state.proceso_asignar);
      formData.append("conductor_id", this.conductor_id);

      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          toastr.success("Conductor asignado con éxito a la solicitud de servicio");
          
            SocketioService.socket.emit("notificacion", response.data.notificaciones);
          // Mandamos a listar los servicios principales  
            const validateNy  = {
              user_id: this.$store.state.user.id,
              recurso:'LISTAR'
            }
          SocketioService.socket.emit("notificacion", validateNy)

          this.loaderBloque = false;
          $("#asignarConductorServicio").modal("hide");
          this.$store.state.mostrarAsignar = [];
          // this.$store.state.mostrar = response.data.servicio;
          this.conductor_id = null;
          // this.$parent.Listar();
        })
        .catch((e) => {
          this.loaderBloque = false;
          console.log(e);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.activeSelect {
  border: 2px solid #2196f3;
}

.listHover:hover {
  background: #fcfcfc;
  transition: 0.4s;
}
.botonLogo {
  border: 1px dashed #2196f3;
  border-radius: 10px;
  background: #eee;
  padding-block: 3px;
}
.image-container {
  width: 100px;
  height: 100px;
  border: 2px dashed #2196f3;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
  font-size: 38px;
  color: #eee;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.doble {
  flex-direction: row;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .espacio {
    padding-inline: 20px;
  }
}

.form {
  .form-group {
    width: 100%;
  }
}
</style>
