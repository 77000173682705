const userDefaults = JSON.parse(localStorage.getItem('user')) || null;
const notificacionesDefault = JSON.parse(localStorage.getItem('notificacionesActive')) || false;

const state = {
    user: userDefaults,
    token: localStorage.getItem('access_token') || null,
    loader: false,
    loaderMensaje: "Cargando ...",
    efecto: false,
    efectoMensaje: "Cargando ...",
    notificaciones: [],
    alertNotificacion: false,
    busqueda: null,
    sidebarActive: false,
    notificacionesActive: notificacionesDefault,
    mostrar: null,
    proceso_asignar:'',
    mostrarAsignar:null,
    screen:true,
    ignoredCountries: ['*'], // Ignore all other countries
    inputOptions: {
        placeholder: 'Número de teléfono',
    },

    listFilterMobile:false,
    notificacionesActiveFull: false,
    menuMobileScreen:false,


    // Paletas de colores segun su bloque  
    // concesionarios 
    cboton:'##AB47BC',

    //Servicios
    sboton:'#178a9e',
    








}

export default state