var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline-container",staticStyle:{"padding-inline":"20px"}},[_c('h2',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"timeline"},_vm._l((_vm.events),function(event,index){return _c('div',{key:index,class:[
        'timeline-item',
        {
          'timeline-item--active': _vm.isActive(index),
          'timeline-item--grey': _vm.isGrey(index),
        },
      ]},[_c('div',{staticClass:"timeline-marker"}),_c('div',{staticClass:"timeline-content"},[_c('h3',{staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(event.status))]),_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(event.date)+" | "),_c('span',{staticStyle:{"color":"#03a9f4"}},[_vm._v(_vm._s(event.description))])])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }