import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'

import Maqueta from '../Maquetas/admin/index.vue'
import maquetaAuth from '../Maquetas/Auth/index.vue'
import maquetaCreate from '../Maquetas/create/index.vue'

Vue.use(VueRouter)

const routes = [
    //  Rutas para el auth  
     //  Rutas para el auth  
     {
        path: '/',
        name: 'maquetaAuth',
        component: maquetaAuth,
        children: [{
            path: '',
            name: 'Login',
            component: () =>
                import ('../Screen/Auth/Login.vue')
        },
        {
            path: 'login2',
            name: 'Login2',
            component: () =>
                import ('../Screen/Auth/Login2.vue')
        }]

    },
   
    {
        path: '/dashboard',
        name: 'Maqueta',
        component: Maqueta,
        children:[
            {
                path:'/',
                name:'Home',
                component: () => import('../Screen/Dashboard.vue'),
            },
            {
            path:'services',
            name:'Services',
            component: () => import('../Screen/Servicios/index.vue'),
        },
        {
            path:'request-services',
            name:'serviceRequest',
            component: () => import('../Screen/requestServices/index.vue'),
        },
        
        {
            path:'dealers',
            name:'Dealers',
            component: () => import('../Screen/Concesionarios/index.vue'),
        },
        {
            path:'dealers/:id/management',
            name:'dealersManagement',
            component: () => import('../Screen/Concesionarios/dealersManagement.vue'),
            props:true,
        },
        {
            path:'workshop',
            name:'Taller',
            component: () => import('../Screen/Taller/index.vue'),
        },
        {
            path:'workshop/:id/management',
            name:'workshopManagement',
            component: () => import('../Screen/Taller/workshopManagement.vue'),
            props:true,
        },
        {
            path:'drivers',
            name:'Conductores',
            component: () => import('../Screen/Conductores/index.vue'),
        },
        {
            path:'drivers/dealer',
            name:'ConductoresDealer',
            component: () => import('../Screen/Conductores/conductoresDealer.vue'),
        },
        
        {
            path:'coordinators',
            name:'Coordinador',
            component: () => import('../Screen/Coordinadores/index.vue'),
        },

        {
            path:'profile',
            name:'Profile',
            component: () => import('../Screen/Profile.vue'),
        },



        {
            path:'invoices',
            name:'Invoices',
            component: () => import('../Screen/Facturas/index.vue'),
        },
        {
            path:'invoices/concesionario',
            name:'adminConcesionarioInvoices',
            component: () => import('../Screen/Facturas/adminInvoiceConcesionario.vue'),
        },

        {
            path:'invoices/:concesionario_id/:year/:month',
            name:'ConcesionarioInvoices',
            component: () => import('../Screen/Facturas/invoiceConcesionario.vue'),
            props:true
        },
        {
            path:'version_apk',
            name:'Version_Apk',
            component: () => import('../Screen/APK/index.vue'),
        },
        
    ]


    },

    {
        path:'/manage',
        name:'vistaCompleta',
        component: maquetaCreate, 
        props:true,
        children: [{
            path:'create/:id/:concesionario_id/service',
            name:'createService',
            component: () => import('../Screen/Servicios/create.vue'),
            props:true,
        },
        {
            path:'update/:id/:concesionario_id/service/:servicio_id/edit',
            name:'updateService',
            component: () => import('../Screen/Servicios/update.vue'),
            props:true,
        }]

    }

]

const router = new VueRouter({
    mode: 'history',
    base: '',
    routes
})


// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         if (!store.getters.loggedIn) {
//             console.log('requiresAuth');
//             next({ name: 'Login' })
//         } else {
//             next()
//         }
//     } else {
//         console.log('NO requiresAuth');
//         next() // make sure to always call next()!
//     }
// })





export default router