<template>
  <div class="navCreate d-flex">
    <div class="d-flex flex-row">
      <div class="logo">
        <img src="/img/marca/logo.png" alt="" style="width: 179px" />
      </div>
      <div class="textoNav d-flex flex-column" style="margin-left: 70px">
        <div class="principal">Crear solicitud de servicios</div>
        <small
          >Completa la información necesaria para crear la solicitud de servicio</small
        >
      </div>
    </div>
    <div class="cerrar" @click.prevent="irRuta()" style="cursor:pointer">
      <i class="fa-regular fa-xmark" style="font-size: 30px"></i>
      
    </div>
  </div>
</template>
<script>
   export default {
    methods: {
      irRuta(){
        this.$router.push({name:'Services'})
      }
    }
   }
 </script>
<style lang="scss" scoped>
.navCreate {
  background: white;
  height: 75px;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  align-items: center;
  padding-inline: 120px;
}
</style>
